// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
    overflow-x: hidden;
}
.link{
    color: #222;
    letter-spacing: 1px;
    display: inline-block;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease;
    text-decoration: none
}
.link:focus{ color: #555; }
.link:hover{ color: #fff; text-decoration: none }
.link:before,
.link:after{
    content: '';
    background-color: skyblue;
    height: 100%;
    width: 100%;
    transform: scale(0);
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    transition: all 0.3s ease;
    text-decoration: none
}
.link:after{  
    background-color: transparent;
    border-top: 1px solid skyblue;
    border-bottom: 1px solid skyblue;
    text-decoration: none
}
.link:hover:before{ transform: scale(1.05,1.2); }
.link:hover:after{ transform: scale(1,1.5); }
@media only screen and (max-width: 900px){
    .link{ margin-bottom: 30px;}
   
}

  
  /* Media query for screens with a max-width of 240px */
  @media screen and (max-width: 240px) {
    /* Update the CSS for the email address within the media query */
    
    .email {
    font-size:1px !important;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/textcomp.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,mBAAmB;IACnB,qBAAqB;IACrB,kBAAkB;IAClB,UAAU;IACV,yBAAyB;IACzB;AACJ;AACA,aAAa,WAAW,EAAE;AAC1B,aAAa,WAAW,EAAE,sBAAsB;AAChD;;IAEI,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,WAAW;IACX,yBAAyB;IACzB;AACJ;AACA;IACI,6BAA6B;IAC7B,6BAA6B;IAC7B,gCAAgC;IAChC;AACJ;AACA,oBAAoB,0BAA0B,EAAE;AAChD,mBAAmB,uBAAuB,EAAE;AAC5C;IACI,OAAO,mBAAmB,CAAC;;AAE/B;;;EAGE,sDAAsD;EACtD;IACE,gEAAgE;;IAEhE;IACA,wBAAwB;IACxB;EACF","sourcesContent":["body{\r\n    overflow-x: hidden;\r\n}\r\n.link{\r\n    color: #222;\r\n    letter-spacing: 1px;\r\n    display: inline-block;\r\n    position: relative;\r\n    z-index: 1;\r\n    transition: all 0.3s ease;\r\n    text-decoration: none\r\n}\r\n.link:focus{ color: #555; }\r\n.link:hover{ color: #fff; text-decoration: none }\r\n.link:before,\r\n.link:after{\r\n    content: '';\r\n    background-color: skyblue;\r\n    height: 100%;\r\n    width: 100%;\r\n    transform: scale(0);\r\n    position: absolute;\r\n    left: 0;\r\n    top: 0;\r\n    z-index: -1;\r\n    transition: all 0.3s ease;\r\n    text-decoration: none\r\n}\r\n.link:after{  \r\n    background-color: transparent;\r\n    border-top: 1px solid skyblue;\r\n    border-bottom: 1px solid skyblue;\r\n    text-decoration: none\r\n}\r\n.link:hover:before{ transform: scale(1.05,1.2); }\r\n.link:hover:after{ transform: scale(1,1.5); }\r\n@media only screen and (max-width: 900px){\r\n    .link{ margin-bottom: 30px;}\r\n   \r\n}\r\n\r\n  \r\n  /* Media query for screens with a max-width of 240px */\r\n  @media screen and (max-width: 240px) {\r\n    /* Update the CSS for the email address within the media query */\r\n    \r\n    .email {\r\n    font-size:1px !important;\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
