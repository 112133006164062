// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.main {
  width: 100%;
  height: 100vh;
  position: relative;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main-text {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.main-text h2 {
  font-size: 3rem;
  font-weight: 800;
  background: #222;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.main-text h1 {
  font-size: 3rem;
  font-weight: 800;
  background: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.main-text p {
  font-size: 1.3rem;
  color: #fff;
  padding: 0.5rem 0 2rem 0;
}
.main-text .show {
  text-decoration: none;
  background: #fff;
  padding: 1rem 2rem;
  border-radius: 6px;
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: #222;
}
@media screen and (max-width: 555px) {
  .main-text h1 {
    padding: 10px 20px;
  }
  .main-text p {
    font-size: 1.1rem;
    padding: 0 0 2 rem 0;
  }
  .main-text .show {
    border-radius: 6px;
    font-size: 1rem;
    padding: 0.6rem 1.1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Main.css"],"names":[],"mappings":";AACA;EACE,WAAW;EACX,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,WAAW;AACb;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,6BAA6B;EAC7B,oCAAoC;AACtC;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,6BAA6B;EAC7B,oCAAoC;AACtC;AACA;EACE,iBAAiB;EACjB,WAAW;EACX,wBAAwB;AAC1B;AACA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;EACjB,mBAAmB;EACnB,WAAW;AACb;AACA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,iBAAiB;IACjB,oBAAoB;EACtB;EACA;IACE,kBAAkB;IAClB,eAAe;IACf,sBAAsB;EACxB;AACF","sourcesContent":["\r\n.main {\r\n  width: 100%;\r\n  height: 100vh;\r\n  position: relative;\r\n}\r\nimg {\r\n  width: 100%;\r\n  height: 100%;\r\n  object-fit: cover;\r\n}\r\n.main-text {\r\n  position: absolute;\r\n  top: 55%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n  width: 100%;\r\n}\r\n.main-text h2 {\r\n  font-size: 3rem;\r\n  font-weight: 800;\r\n  background: #222;\r\n  -webkit-background-clip: text;\r\n  -webkit-text-fill-color: transparent;\r\n}\r\n.main-text h1 {\r\n  font-size: 3rem;\r\n  font-weight: 800;\r\n  background: #fff;\r\n  -webkit-background-clip: text;\r\n  -webkit-text-fill-color: transparent;\r\n}\r\n.main-text p {\r\n  font-size: 1.3rem;\r\n  color: #fff;\r\n  padding: 0.5rem 0 2rem 0;\r\n}\r\n.main-text .show {\r\n  text-decoration: none;\r\n  background: #fff;\r\n  padding: 1rem 2rem;\r\n  border-radius: 6px;\r\n  font-size: 1.1rem;\r\n  font-weight: bold;\r\n  letter-spacing: 2px;\r\n  color: #222;\r\n}\r\n@media screen and (max-width: 555px) {\r\n  .main-text h1 {\r\n    padding: 10px 20px;\r\n  }\r\n  .main-text p {\r\n    font-size: 1.1rem;\r\n    padding: 0 0 2 rem 0;\r\n  }\r\n  .main-text .show {\r\n    border-radius: 6px;\r\n    font-size: 1rem;\r\n    padding: 0.6rem 1.1rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
