/*
CloudZap by Cloud Serve Pvt. Ltd
*/
import im1 from "../components/images/CloudZap.jpg";
import { Component } from "react";
import "./navbar.css";
import { MenuItems } from "./Menu";
import {Link} from "react-router-dom";
import './header.css';




class Navbar extends Component{
     state  = {clicked : false};//making it responsive by clicking cross and bar
     handleClick = () =>{
        this.setState({clicked : !this.state.clicked})
     }

     render()   /*render is required when you extend the component*/
     {
            return ( 
                // making a navbar adding all the menu items here!
                //for navbar edits make all changes under ul tags
                // mapping to the array created in menu items and the title 
            
                <nav className="NavbarItems">
                    <div className="logo"> 
                    <a href="/home"><img src={im1} alt="logo"/></a>
                    
                    </div>
                   

                <div className="menu-icons" onClick={this.handleClick}>
                    <i className={this.state.clicked ? "fas fa-times " : "fas fa-bars"}>
                    </i>

                </div>
                     <ul className= {this.state.clicked ? "nav-menu active" : "nav-menu"}> 
                       
                        {MenuItems.map((item,index) => {
                            return(
                                <li key ={index} >
                                    <Link className={item.cName}  to = {item.url}> {item.title}</Link>
                                    

                                </li>
                                 );
                     })}
                      <div className="dropdown">
                                        <button className="dropbtn" style={{ fontSize: '1.2rem',backgroundColor:'#ffffff' ,display:'inline-block'}}>Services</button>
                        <div className="dropdown-content bg-white border" style={{ fontSize: '1.2rem' }}>
                             {/* <a href="http://cloudzap.cloudservesystems.com:8000/Cloudzap/login/" >Amazon Web Services</a> */}
                            <a href="https://cloudzapteams.cloudservesystems.com" target="_blank" >Azure</a>
                            
                       </div>
                     </div>

                    <li className="nav-item" style={{ fontSize: '1.2rem' }}>
                        <a className="nav-link active" href="/contactus">Contact Us</a>
                    </li>           
                     
                    <a href='/demo'><button style={{borderRadius:'7px',border:'none', display:'inline-block'}}>Book a Demo</button> </a>
                </ul>
                </nav>
              );

              
     }
}
export default Navbar;