// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card h1 {
  font-size: 3rem;
}
.card {
  margin: 4rem 6rem;
  color: #2a2a2a;
}
.carddata {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
}
.t-card {
  width: 32%;
  text-align: start;
  box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
  border-radius: 7px;
  cursor: pointer;
  padding: 1rem 0.8 rem;
}
.t-image {
  height: 200px;
  overflow: hidden;
  border-radius: 7px;
}
.t-image img {
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
  border-radius: 7px;
}
.t-image:hover {
  transform: scale(1.3);
}
.t-card h4 {
  font-size: 1.3rem;
  padding: 0.9rem 0 0.2rem 0;
}

@media screen and (max-width: 850px) {
  .card {
    margin: 4rem 2rem;
  }
  .carddata {
    flex-direction: column;
  }
  .t-card {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/cards.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;AACA;EACE,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,UAAU;EACV,iBAAiB;EACjB,8CAA8C;EAC9C,kBAAkB;EAClB,eAAe;EACf,qBAAqB;AACvB;AACA;EACE,aAAa;EACb,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,YAAY;EACZ,4BAA4B;EAC5B,kBAAkB;AACpB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,iBAAiB;EACjB,0BAA0B;AAC5B;;AAEA;EACE;IACE,iBAAiB;EACnB;EACA;IACE,sBAAsB;EACxB;EACA;IACE,WAAW;IACX,qBAAqB;EACvB;AACF","sourcesContent":[".card h1 {\r\n  font-size: 3rem;\r\n}\r\n.card {\r\n  margin: 4rem 6rem;\r\n  color: #2a2a2a;\r\n}\r\n.carddata {\r\n  margin-top: 3rem;\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n.t-card {\r\n  width: 32%;\r\n  text-align: start;\r\n  box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);\r\n  border-radius: 7px;\r\n  cursor: pointer;\r\n  padding: 1rem 0.8 rem;\r\n}\r\n.t-image {\r\n  height: 200px;\r\n  overflow: hidden;\r\n  border-radius: 7px;\r\n}\r\n.t-image img {\r\n  width: 100%;\r\n  height: 100%;\r\n  transition: 0.3s ease-in-out;\r\n  border-radius: 7px;\r\n}\r\n.t-image:hover {\r\n  transform: scale(1.3);\r\n}\r\n.t-card h4 {\r\n  font-size: 1.3rem;\r\n  padding: 0.9rem 0 0.2rem 0;\r\n}\r\n\r\n@media screen and (max-width: 850px) {\r\n  .card {\r\n    margin: 4rem 2rem;\r\n  }\r\n  .carddata {\r\n    flex-direction: column;\r\n  }\r\n  .t-card {\r\n    width: 100%;\r\n    margin-bottom: 1.5rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
