// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-work-data{
    text-align: center;
    transition: all 1.2s linear;
    background-color: skyblue;
    padding-top: 50px;
    padding-bottom: 50px;
    flex-direction: row;
    flex-wrap: nowrap;
    
}

.counter-numbers {
  font-size: 1.8rem;
  text-align: center;
  align-items: center;
margin: auto;
 
}

.section-work-data p {
  color: var(--white);
  text-transform: capitalize;
  
}

.grid-four-column h2{
    font-size: 3.0rem;
    text-align: center;
    flex-direction: column;
    color: #fff;
    margin-left: 80px;
    justify-content: space-between;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.container3{
  margin-left: 300px;
}
.grid-four-column p{
    font-size: 1.2rem;
    color: #fff;
    margin-left: 80px;
    text-align: center;
    text-transform:uppercase;
    justify-content: space-between;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  
    
}
`, "",{"version":3,"sources":["webpack://./src/components/count.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,2BAA2B;IAC3B,yBAAyB;IACzB,iBAAiB;IACjB,oBAAoB;IACpB,mBAAmB;IACnB,iBAAiB;;AAErB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;AACrB,YAAY;;AAEZ;;AAEA;EACE,mBAAmB;EACnB,0BAA0B;;AAE5B;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,sBAAsB;IACtB,WAAW;IACX,iBAAiB;IACjB,8BAA8B;IAC9B,4DAA4D;AAChE;AACA;EACE,kBAAkB;AACpB;AACA;IACI,iBAAiB;IACjB,WAAW;IACX,iBAAiB;IACjB,kBAAkB;IAClB,wBAAwB;IACxB,8BAA8B;IAC9B,4DAA4D;;;AAGhE","sourcesContent":[".section-work-data{\r\n    text-align: center;\r\n    transition: all 1.2s linear;\r\n    background-color: skyblue;\r\n    padding-top: 50px;\r\n    padding-bottom: 50px;\r\n    flex-direction: row;\r\n    flex-wrap: nowrap;\r\n    \r\n}\r\n\r\n.counter-numbers {\r\n  font-size: 1.8rem;\r\n  text-align: center;\r\n  align-items: center;\r\nmargin: auto;\r\n \r\n}\r\n\r\n.section-work-data p {\r\n  color: var(--white);\r\n  text-transform: capitalize;\r\n  \r\n}\r\n\r\n.grid-four-column h2{\r\n    font-size: 3.0rem;\r\n    text-align: center;\r\n    flex-direction: column;\r\n    color: #fff;\r\n    margin-left: 80px;\r\n    justify-content: space-between;\r\n    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\r\n}\r\n.container3{\r\n  margin-left: 300px;\r\n}\r\n.grid-four-column p{\r\n    font-size: 1.2rem;\r\n    color: #fff;\r\n    margin-left: 80px;\r\n    text-align: center;\r\n    text-transform:uppercase;\r\n    justify-content: space-between;\r\n    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\r\n  \r\n    \r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
