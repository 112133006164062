// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/*.from-container {
    margin: 4rem 6rem;
    color: #2a2a2a;
   
}*/
.from-container form{
    display:flex;
    flex-direction: column;
    width:100%;
    margin-left: auto;    
}
.from-container input{
    height:3rem;
    padding : 0 1rem;
    margin-bottom: 2rem;
    border-radius: 0.3rem;
    border:1px solid #2a2a2a;
}
.from-container select{
    height:3rem;
    padding : 0 1rem;
    margin-bottom: 2rem;
    border-radius: 0.3rem;
    border:1px solid #2a2a2a;
}
.from-container textarea{
    height:3rem;
    padding : 0 1rem;
    margin-bottom: 2rem;
    border-radius: 0.3rem;
    border:1px solid #2a2a2a;
}
.from-container button{
    display: inline;
    border-radius: 6px;
 
}




@media screen and (max-width:850px){
     .from-container{
        margin: 4rem 2rem;
    
     }
     .from-containerform{
        padding-top: 2rem;
        width: 90%;
     }
}
`, "",{"version":3,"sources":["webpack://./src/components/contact.css"],"names":[],"mappings":";AACA;;;;EAIE;AACF;IACI,YAAY;IACZ,sBAAsB;IACtB,UAAU;IACV,iBAAiB;AACrB;AACA;IACI,WAAW;IACX,gBAAgB;IAChB,mBAAmB;IACnB,qBAAqB;IACrB,wBAAwB;AAC5B;AACA;IACI,WAAW;IACX,gBAAgB;IAChB,mBAAmB;IACnB,qBAAqB;IACrB,wBAAwB;AAC5B;AACA;IACI,WAAW;IACX,gBAAgB;IAChB,mBAAmB;IACnB,qBAAqB;IACrB,wBAAwB;AAC5B;AACA;IACI,eAAe;IACf,kBAAkB;;AAEtB;;;;;AAKA;KACK;QACG,iBAAiB;;KAEpB;KACA;QACG,iBAAiB;QACjB,UAAU;KACb;AACL","sourcesContent":["\r\n/*.from-container {\r\n    margin: 4rem 6rem;\r\n    color: #2a2a2a;\r\n   \r\n}*/\r\n.from-container form{\r\n    display:flex;\r\n    flex-direction: column;\r\n    width:100%;\r\n    margin-left: auto;    \r\n}\r\n.from-container input{\r\n    height:3rem;\r\n    padding : 0 1rem;\r\n    margin-bottom: 2rem;\r\n    border-radius: 0.3rem;\r\n    border:1px solid #2a2a2a;\r\n}\r\n.from-container select{\r\n    height:3rem;\r\n    padding : 0 1rem;\r\n    margin-bottom: 2rem;\r\n    border-radius: 0.3rem;\r\n    border:1px solid #2a2a2a;\r\n}\r\n.from-container textarea{\r\n    height:3rem;\r\n    padding : 0 1rem;\r\n    margin-bottom: 2rem;\r\n    border-radius: 0.3rem;\r\n    border:1px solid #2a2a2a;\r\n}\r\n.from-container button{\r\n    display: inline;\r\n    border-radius: 6px;\r\n \r\n}\r\n\r\n\r\n\r\n\r\n@media screen and (max-width:850px){\r\n     .from-container{\r\n        margin: 4rem 2rem;\r\n    \r\n     }\r\n     .from-containerform{\r\n        padding-top: 2rem;\r\n        width: 90%;\r\n     }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
