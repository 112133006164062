// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,300&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
    font-family: 'Poppins', sans-serif;
    
}

.images{
    float: left;
    width: 220px;
    padding: 15px;
    overflow-x: hidden;
}
.row1::after{
    content: "";
    display: table;
    clear: both;
}
@media only screen and (max-width: 1000px){
    .images{
       
        width: 300px;
        margin-left: 120px;
    }
}

@media only screen and (max-width: 430px) {
    /* Reduce the size of the icons for screens with max width 430px or less */
    .images {
      width: 80px; /* Adjust the width as needed */

    }
  }
  @media only screen and (max-width: 245px) {
    /* Reduce the size of the icons for screens with max width 430px or less */
    .images {
      width: 50px; /* Adjust the width as needed */

    }
  }`, "",{"version":3,"sources":["webpack://./src/routes/partner.css"],"names":[],"mappings":"AACA;IACI,kCAAkC;;AAEtC;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,cAAc;IACd,WAAW;AACf;AACA;IACI;;QAEI,YAAY;QACZ,kBAAkB;IACtB;AACJ;;AAEA;IACI,0EAA0E;IAC1E;MACE,WAAW,EAAE,+BAA+B;;IAE9C;EACF;EACA;IACE,0EAA0E;IAC1E;MACE,WAAW,EAAE,+BAA+B;;IAE9C;EACF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,300&display=swap');\r\n*{\r\n    font-family: 'Poppins', sans-serif;\r\n    \r\n}\r\n\r\n.images{\r\n    float: left;\r\n    width: 220px;\r\n    padding: 15px;\r\n    overflow-x: hidden;\r\n}\r\n.row1::after{\r\n    content: \"\";\r\n    display: table;\r\n    clear: both;\r\n}\r\n@media only screen and (max-width: 1000px){\r\n    .images{\r\n       \r\n        width: 300px;\r\n        margin-left: 120px;\r\n    }\r\n}\r\n\r\n@media only screen and (max-width: 430px) {\r\n    /* Reduce the size of the icons for screens with max width 430px or less */\r\n    .images {\r\n      width: 80px; /* Adjust the width as needed */\r\n\r\n    }\r\n  }\r\n  @media only screen and (max-width: 245px) {\r\n    /* Reduce the size of the icons for screens with max width 430px or less */\r\n    .images {\r\n      width: 50px; /* Adjust the width as needed */\r\n\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
