// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{

   font-family: sans-serif;
}
.container1{
    max-width: 1300px;
    width:100%;
    padding: 0px 20px;
    margin: 0 -5px;
    
}

section{
    min-height: 100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
}
.h1{
    font-size: 50px;
    line-height: 60px;
    text-align: center;
    align-items: center;
    margin-bottom: 40px;

}


.c2{
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(33.33% - 20px);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    width:100%;
   margin-left: 40px;
    font-size: 20px;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    border-radius: 2px solid transparent;
}
.c2:hover{
    border:2px solid #222;
}
.c2 i{
    font-size: 1.5rem;
}
@media(max-width:969px){
    section{
        padding: 20px 0px;
    }
    h1{
        font-size: 30px;
        line-height: 60px;
        
    
    }
    .c2{
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(50%- 20px);
        max-width:calc(50% - 20px);
        
    }
}
@media(max-width:600px){
    .c2{
        flex: 0 0 100%;
        max-width:100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/works.css"],"names":[],"mappings":"AAAA;;GAEG,uBAAuB;AAC1B;AACA;IACI,iBAAiB;IACjB,UAAU;IACV,iBAAiB;IACjB,cAAc;;AAElB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;;AAEvB;;;AAGA;IACI,YAA4B;IAA5B,cAA4B;IAA5B,+BAA4B;IAC5B,0CAA0C;IAC1C,UAAU;GACX,iBAAiB;IAChB,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,oCAAoC;AACxC;AACA;IACI,qBAAqB;AACzB;AACA;IACI,iBAAiB;AACrB;AACA;IACI;QACI,iBAAiB;IACrB;IACA;QACI,eAAe;QACf,iBAAiB;;;IAGrB;IACA;QACI,YAAwB;QAAxB,cAAwB;QAAxB,2BAAwB;QACxB,0BAA0B;;IAE9B;AACJ;AACA;IACI;QACI,cAAc;QACd,cAAc;IAClB;AACJ","sourcesContent":["*{\r\n\r\n   font-family: sans-serif;\r\n}\r\n.container1{\r\n    max-width: 1300px;\r\n    width:100%;\r\n    padding: 0px 20px;\r\n    margin: 0 -5px;\r\n    \r\n}\r\n\r\nsection{\r\n    min-height: 100vh;\r\n    display:flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n}\r\n.h1{\r\n    font-size: 50px;\r\n    line-height: 60px;\r\n    text-align: center;\r\n    align-items: center;\r\n    margin-bottom: 40px;\r\n\r\n}\r\n\r\n\r\n.c2{\r\n    flex:0 0 calc(33.33% - 20px);\r\n    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);\r\n    width:100%;\r\n   margin-left: 40px;\r\n    font-size: 20px;\r\n    background: #fff;\r\n    padding: 20px;\r\n    border-radius: 10px;\r\n    margin-bottom: 20px;\r\n    border-radius: 2px solid transparent;\r\n}\r\n.c2:hover{\r\n    border:2px solid #222;\r\n}\r\n.c2 i{\r\n    font-size: 1.5rem;\r\n}\r\n@media(max-width:969px){\r\n    section{\r\n        padding: 20px 0px;\r\n    }\r\n    h1{\r\n        font-size: 30px;\r\n        line-height: 60px;\r\n        \r\n    \r\n    }\r\n    .c2{\r\n        flex:0 0 calc(50%- 20px);\r\n        max-width:calc(50% - 20px);\r\n        \r\n    }\r\n}\r\n@media(max-width:600px){\r\n    .c2{\r\n        flex: 0 0 100%;\r\n        max-width:100%;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
