// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.img{
  margin-top: 100px;
  align-items: center;
  justify-content: center;
}
.b1:hover{
       /*background-color:#222;*/
       color: #fff;
  }
  .c {
    display:flex;
    flex-direction: column;
    justify-content: center;
    width:80%;
    margin-left: auto;    
}

  .c button{
    display: inline;
    border-radius: 6px;
 
}

.row-m
{
margin: auto;
  text-align: center;
}
.row-m1
{
  display: flex;
  align-items: center;
  justify-content: center;
}

.b1{
  border-radius: 6px;
  border-color: #222;  
}




@media screen and (max-width:850px){
  .from-container{
     margin: 4rem 2rem;
 
  }
  .from-containerform{
     padding-top: 2rem;
     width: 90%;
  }
}

`, "",{"version":3,"sources":["webpack://./src/routes/demo.css"],"names":[],"mappings":";AACA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,uBAAuB;AACzB;AACA;OACO,yBAAyB;OACzB,WAAW;EAChB;EACA;IACE,YAAY;IACZ,sBAAsB;IACtB,uBAAuB;IACvB,SAAS;IACT,iBAAiB;AACrB;;EAEE;IACE,eAAe;IACf,kBAAkB;;AAEtB;;AAEA;;AAEA,YAAY;EACV,kBAAkB;AACpB;AACA;;EAEE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;;;;AAKA;EACE;KACG,iBAAiB;;EAEpB;EACA;KACG,iBAAiB;KACjB,UAAU;EACb;AACF","sourcesContent":["\r\n.img{\r\n  margin-top: 100px;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n.b1:hover{\r\n       /*background-color:#222;*/\r\n       color: #fff;\r\n  }\r\n  .c {\r\n    display:flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    width:80%;\r\n    margin-left: auto;    \r\n}\r\n\r\n  .c button{\r\n    display: inline;\r\n    border-radius: 6px;\r\n \r\n}\r\n\r\n.row-m\r\n{\r\nmargin: auto;\r\n  text-align: center;\r\n}\r\n.row-m1\r\n{\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\n.b1{\r\n  border-radius: 6px;\r\n  border-color: #222;  \r\n}\r\n\r\n\r\n\r\n\r\n@media screen and (max-width:850px){\r\n  .from-container{\r\n     margin: 4rem 2rem;\r\n \r\n  }\r\n  .from-containerform{\r\n     padding-top: 2rem;\r\n     width: 90%;\r\n  }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
