import 'bootstrap/dist/css/bootstrap.min.css';
import "../components/contact.css";
import Main from "../Main";
import Mission from "../mission";
//import Card from "../cards";
import "../routes/partner.css";
import Footer1 from "../footer1";
import Navbar from "../components/Navbar";
import ContactForm from "../components/contactform";
import azure from "../components/images/azure.png";
import aws from "../components/images/aws.png";
import kubernetes from "../components/images/kubernetes.png";
import p from "../components/images/handshake_partner.jpg";
import "../components/features.css";

function Partner() {
  return (
    <>
   
      <Navbar/>
      
      <Main
        cName="main"
        mainimg={p}
        title="Partners"
        text="CloudZap"
        buttonText="Book a demo"
        url="/demo"
        btnClass="show"
      />
      <Mission />
      <section class="section section-services">
      <div className="container2" >
      <h2 class="common-heading"style={{textAlign:'center', color: '#011B30',fontSize: '2.5vw', fontWeight: '750'}}>Why Partner With Us?</h2>
      <div className="lead mb-4"
         style= {{marginTop:'30px',marginLeft:'40px',textAlign:'center',color:'#05467b', fontSize: '1.5vw',fontWeight: '500', fontStyle:'normal', lineHeight:'2vw', color: '#222222', width: '40vw'}}>
            We share your mission of more compliant future.
          </div> 
       <br/>
         <br/>
      </div>
      <div class="container grid grid-three-column" style={{boxSizing:'border-box' , boxShadow:'6px'}}>
        <div class="service-box">
          <ion-icon name="people"class="service-icon"></ion-icon>
          <h3 ><b>MSP & MSSP PARTNERS</b></h3>
          <p>
          Our MSP & MSSP partners can grow their business by adding compliance and assurance as a service to their portfolio.
          </p>
        </div>

        <div class="service-box">
        <ion-icon name="people"class="service-icon"></ion-icon>
        <h3><b>RESELLER PARTNERS</b></h3>
          <p>We offer competitive margins, sales tools, & enablement for partners that resell and prefer to close the business.</p>
        </div>

        <div class="service-box">
        <ion-icon name="people"class="service-icon"></ion-icon>
        <h3><b>AUDITOR PARTNERS</b></h3>
          <p>Become our auditor partner to provide assurance & audit services to your customers on the CloudZap platform.</p>
        </div>
      </div>
    </section>
    <br/>
      <section class="section section-services">
      <div className="container2" >
      <div className="display-2 m-2" style={{textAlign:'center',color: '#011B30',fontSize: '2.5vw', fontWeight: '750'}}>We 
      Take Partnership Seriously</div>
        <h2 class="common-heading"></h2>
        <div className="lead mb-4"
         style= {{marginTop:'30px',marginLeft:'40px',textAlign:'center',color:'#05467b', fontSize: '1.5vw',fontWeight: '500', fontStyle:'normal', lineHeight:'2vw', color: '#222222', width: '40vw'}}>
       We empower MSPs, Resellers , and Security providers to become more profitable by expanding their portfolio of services by adding compliance
       as a service to their portfolio of offerings.
    </div> 
       <br/>
      </div>
      <div class="container grid grid-three-column" style={{boxSizing:'border-box' , boxShadow:'6px'}}>
        <div class="service-box">
        <ion-icon name="settings"class="service-icon"></ion-icon>
          <h3><b>TOOLS & SERVICES</b></h3>
          <p>
          End-to-end suite of security compliance tools and a partner portal for partners and service providers to access their customers seamlessly.
          </p>
        </div>

        <div class="service-box">
        <ion-icon name="document-text"class="service-icon"></ion-icon>
        <h3><b>PARTNERSHIP MODELS</b></h3>
          <p>
          Be a reseller, a referral partner, or actively use our platform to make your customers compliant.
          </p>
        </div>

        <div class="service-box">
        <ion-icon name="bag-check"class="service-icon"></ion-icon>
        <h3><b>ENABLEMENT & SUPPORT</b></h3>
          <p>A rich set of enablement tools such as free training instances, marketing demos, sales tools.
          </p>
        </div>
      </div>
    </section>
        
      <section> 
        <br/>
     { /*<div className="display-6 mb-4"
                                style= {{color:'#011B30', fontSize: '2vw',fontWeight: '750',letterSpacing: '0.05vh'}}>
                                TOOLS AND TECHNOLOGIES</div>*/}
             <div className="lead mb-4"
                         style= {{color:'#05467b', fontSize: '1.5vw',fontWeight: '500', fontStyle:'normal', lineHeight:'2vw', color: '#222222', width: '40vw'}}>
         Cloudzap's Trusted Partners Bring Optimal Technology, Relationships And Know-How To Cloud Transformations. We Work In Harmony For Client Success.</div>
      <br/><br/><br/>
      <div classname="row1">
        <div className="images">
          <img id="pic" src={azure} alt="icon" width="250px"/>
          <p>Microsoft Azure</p>
          </div>
          <div className="images">
          <img id="pic" src={aws} alt="icon" width="250px"/>
          <p>AWS</p>
          </div>
          <div className="images">
          <img id="pic" src={kubernetes} alt="icon" width="250px"/>
          <p>Kubernetes</p>      
        </div> 
      </div>
      </section>
     {/*<Card />*/} 
    { /* <ContactForm/>*/}
    <div className="container text-center" style= {{marginTop:'-60px'}}>
                <div className="row align-items-start">
                    <div className="col">
                        <div>
                        <div className="display-5 mb-4"
                                    style= {{marginTop:'50px',marginLeft:'50px',textAlign:'left',color:'#011B30', fontSize: '3vw',fontWeight: '750',letterSpacing: '0.05vh'}}>
                                Interested In Partnering?</div>
                        
 <div className="lead mb-4" style= {{marginLeft:'50px',textAlign:'left',color:'#05467b',  fontSize: '1.5vw',fontWeight: '500', fontStyle:'normal', lineHeight:'2vw', color: '#22222', width: '40vw'}}>
                At CloudZap, We are constantly evaluating platforms and that can help us deliver
          better outcomes for our clients. If you are interested in joining the network, please fill out the quick form or you can mail us on info@cloudservesystems.com</div>
                                    
                                  
                        </div>
                    </div>
  <div className="col svg1 align-items-center justify-content-center">
     <div>
     <div className="from-container">
    <form  action="https://api.web3forms.com/submit"
  method="POST">
     <input type="hidden" name="redirect" value="https://cloudzap.cloudservesystems.com/thanks"/>
                 <input type="hidden" name="access_key" value="83e1ce45-4085-46ae-982d-2c9ddb99d801"/>
                <input required
                placeholder="Name"
                name ="Name"
                 autoComplete="off"/>
                <input 
                required
                name ="Work Email"
                placeholder="Work Email"
                type="email"
                autoComplete="off"/>
                <input 
                 name ="Company Name"
                required  
                placeholder="Company Name"
                 autoComplete="off"/>
                
         <label style={{textAlign:"left"}} required>Select your country</label>      
<select placeholder="select" id="country" name="country" required>
<option value="" >None</option>
    <option >Afghanistan</option>
    <option >Aland Islands</option>
    <option >Albania</option>
    <option >Algeria</option>
    <option >American Samoa</option>
    <option >Andorra</option>
    <option >Angola</option>
    <option >Anguilla</option>
    <option >Antarctica</option>
    <option >Antigua and Barbuda</option>
    <option >Argentina</option>
    <option >Armenia</option>
    <option >Aruba</option>
    <option >Australia</option>
    <option >Austria</option>
    <option >Azerbaijan</option>
    <option >Bahamas</option>
    <option >Bahrain</option>
    <option >Bangladesh</option>
    <option >Barbados</option>
    <option >Belarus</option>
    <option >Belgium</option>
    <option >Belize</option>
    <option >Benin</option>
    <option >Bermuda</option>
    <option >Bhutan</option>
    <option >Bolivia</option>
    <option >Bonaire, Sint Eustatius and Saba</option>
    <option >Bosnia and Herzegovina</option>
    <option >Botswana</option>
    <option >Bouvet Island</option>
    <option >Brazil</option>
    <option >British Indian Ocean Territory</option>
    <option >Brunei Darussalam</option>
    <option >Bulgaria</option>
    <option >Burkina Faso</option>
    <option >Burundi</option>
    <option >Cambodia</option>
    <option >Cameroon</option>
    <option >Canada</option>
    <option >Cape Verde</option>
    <option >Cayman Islands</option>
    <option >Central African Republic</option>
    <option >Chad</option>
    <option >Chile</option>
    <option >China</option>
    <option >Christmas Island</option>
    <option >Cocos (Keeling) Islands</option>
    <option >Colombia</option>
    <option >Comoros</option>
    <option >Congo</option>
    <option >Congo, Democratic Republic of the Congo</option>
    <option >Cook Islands</option>
    <option >Costa Rica</option>
    <option >Cote D'Ivoire</option>
    <option >Croatia</option>
    <option >Cuba</option>
    <option >Curacao</option>
    <option >Cyprus</option>
    <option >Czech Republic</option>
    <option >Denmark</option>
    <option >Djibouti</option>
    <option >Dominica</option>
    <option >Dominican Republic</option>
    <option >Ecuador</option>
    <option >Egypt</option>
    <option >El Salvador</option>
    <option >Equatorial Guinea</option>
    <option >Eritrea</option>
    <option >Estonia</option>
    <option >Ethiopia</option>
    <option >Falkland Islands (Malvinas)</option>
    <option >Faroe Islands</option>
    <option >Fiji</option>
    <option >Finland</option>
    <option >France</option>
    <option >French Guiana</option>
    <option >French Polynesia</option>
    <option >French Southern Territories</option>
    <option >Gabon</option>
    <option >Gambia</option>
    <option >Georgia</option>
    <option >Germany</option>
    <option >Ghana</option>
    <option >Gibraltar</option>
    <option >Greece</option>
    <option >Greenland</option>
    <option >Grenada</option>
    <option >Guadeloupe</option>
    <option >Guam</option>
    <option >Guatemala</option>
    <option >Guernsey</option>
    <option >Guinea</option>
    <option >Guinea-Bissau</option>
    <option >Guyana</option>
    <option >Haiti</option>
    <option >Heard Island and Mcdonald Islands</option>
    <option >Holy See (Vatican City State)</option>
    <option >Honduras</option>
    <option>Hong Kong</option>
    <option>Hungary</option>
    <option >Iceland</option>
    <option >India</option>
    <option >Indonesia</option>
    <option >Iran, Islamic Republic of</option>
    <option >Iraq</option>
    <option >Ireland</option>
    <option >Isle of Man</option>
    <option >Israel</option>
    <option >Italy</option>
    <option >Jamaica</option>
    <option >Japan</option>
    <option >Jersey</option>
    <option >Jordan</option>
    <option >Kazakhstan</option>
    <option >Kenya</option>
    <option >Kiribati</option>
    <option >Korea, Democratic People's Republic of</option>
    <option >Korea, Republic of</option>
    <option >Kosovo</option>
    <option >Kuwait</option>
    <option >Kyrgyzstan</option>
    <option >Lao People's Democratic Republic</option>
    <option >Latvia</option>
    <option >Lebanon</option>
    <option >Lesotho</option>
    <option >Liberia</option>
    <option >Libyan Arab Jamahiriya</option>
    <option >Liechtenstein</option>
    <option >Lithuania</option>
    <option >Luxembourg</option>
    <option >Macao</option>
    <option >Macedonia, the Former Yugoslav Republic of</option>
    <option >Madagascar</option>
    <option >Malawi</option>
    <option >Malaysia</option>
    <option >Maldives</option>
    <option >Mali</option>
    <option >Malta</option>
    <option >Marshall Islands</option>
    <option >Martinique</option>
    <option >Mauritania</option>
    <option >Mauritius</option>
    <option >Mayotte</option>
    <option >Mexico</option>
    <option >Micronesia, Federated States of</option>
    <option >Moldova, Republic of</option>
    <option >Monaco</option>
    <option >Mongolia</option>
    <option >Montenegro</option>
    <option >Montserrat</option>
    <option >Morocco</option>
    <option >Mozambique</option>
    <option >Myanmar</option>
    <option >Namibia</option>
    <option >Nauru</option>
    <option >Nepal</option>
    <option >Netherlands</option>
    <option >Netherlands Antilles</option>
    <option >New Caledonia</option>
    <option >New Zealand</option>
    <option >Nicaragua</option>
    <option >Niger</option>
    <option >Nigeria</option>
    <option >Niue</option>
    <option >Norfolk Island</option>
    <option >Northern Mariana Islands</option>
    <option >Norway</option>
    <option >Oman</option>
    <option >Pakistan</option>
    <option >Palau</option>
    <option >Palestinian Territory, Occupied</option>
    <option >Panama</option>
    <option >Papua New Guinea</option>
    <option >Paraguay</option>
    <option >Peru</option>
    <option >Philippines</option>
    <option >Pitcairn</option>
    <option >Poland</option>
    <option >Portugal</option>
    <option >Puerto Rico</option>
    <option >Qatar</option>
    <option >Reunion</option>
    <option >Romania</option>
    <option >Russian Federation</option>
    <option >Rwanda</option>
    <option >Saint Barthelemy</option>
    <option >Saint Helena</option>
    <option >Saint Kitts and Nevis</option>
    <option >Saint Lucia</option>
    <option >Saint Martin</option>
    <option >Saint Pierre and Miquelon</option>
    <option >Saint Vincent and the Grenadines</option>
    <option >Samoa</option>
    <option >San Marino</option>
    <option >Sao Tome and Principe</option>
    <option >Saudi Arabia</option>
    <option >Senegal</option>
    <option >Serbia</option>
    <option >Serbia and Montenegro</option>
    <option >Seychelles</option>
    <option >Sierra Leone</option>
    <option >Singapore</option>
    <option >Sint Maarten</option>
    <option >Slovakia</option>
    <option >Slovenia</option>
    <option >Solomon Islands</option>
    <option >Somalia</option>
    <option >South Africa</option>
    <option >South Georgia and the South Sandwich Islands</option>
    <option >South Sudan</option>
    <option >Spain</option>
    <option >Sri Lanka</option>
    <option >Sudan</option>
    <option >Suriname</option>
    <option >Svalbard and Jan Mayen</option>
    <option >Swaziland</option>
    <option >Sweden</option>
    <option >Switzerland</option>
    <option >Syrian Arab Republic</option>
    <option >Taiwan, Province of China</option>
    <option >Tajikistan</option>
    <option >Tanzania, United Republic of</option>
    <option >Thailand</option>
    <option >Timor-Leste</option>
    <option >Togo</option>
    <option >Tokelau</option>
    <option >Tonga</option>
    <option >Trinidad and Tobago</option>
    <option >Tunisia</option>
    <option >Turkey</option>
    <option >Turkmenistan</option>
    <option >Turks and Caicos Islands</option>
    <option >Tuvalu</option>
    <option >Uganda</option>
    <option >Ukraine</option>
    <option >United Arab Emirates</option>
    <option >United Kingdom</option>
    <option >United States</option>
    <option >United States Minor Outlying Islands</option>
    <option >Uruguay</option>
    <option >Uzbekistan</option>
    <option >Vanuatu</option>
    <option >Venezuela</option>
    <option >Viet Nam</option>
    <option >Virgin Islands, British</option>
    <option >Virgin Islands, U.s.</option>
    <option >Wallis and Futuna</option>
    <option >Western Sahara</option>
    <option >Yemen</option>
    <option >Zambia</option>
    <option >Zimbabwe</option>
</select>   
                <textarea  name ="textArea"
                placeholder="How can we help you?" rows="4"
                 autoComplete="off"></textarea>
                <button type="submit" >Submit</button>

            </form>
            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
            
      <Footer1/>
    </>
  );
}

export default Partner;
