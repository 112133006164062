// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card1{
  margin-left:20px;
  display: flex;
  justify-content: space-between;
  width:250px;
  height: 100px;
  border: 2px solid skyblue;
}
.card2{
  margin-left:20px;
  display: flex;
  justify-content: space-between;
  width:250px;
  height: 100px;
  border: 2px solid skyblue;
}
.card3{
  margin-left:20px;
  display: flex;
  justify-content: space-between;
  width:250px;
  height: 100px;
  border: 2px solid skyblue;
}
.card4{
  margin-left:20px;
  display: flex;
  justify-content: space-between;
  width:250px;
  height: 100px;
  border: 2px solid skyblue;
}
.card1:hover {
    border:3px solid #222;
  }

  .card1:hover .list-group-item{
    background:#00ffb6 !important
  }

  .card2:hover {
    border:3px solid #222;
  }

  .card2:hover .list-group-item{
    background:#00C9FF !important
  }


  .card3:hover {
    border:3px solid #222;
  }

  .card3:hover .list-group-item{
    background:#ff95e9 !important
  }

  .card4:hover {
    border:3px solid #222;
  }

  .card4:hover .list-group-item{
    background:#FF8133 !important
  }


  .card:hover .btn-outline-dark{
    color:white;
    background:#212529;
  }`, "",{"version":3,"sources":["webpack://./src/components/pricecards.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,8BAA8B;EAC9B,WAAW;EACX,aAAa;EACb,yBAAyB;AAC3B;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,8BAA8B;EAC9B,WAAW;EACX,aAAa;EACb,yBAAyB;AAC3B;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,8BAA8B;EAC9B,WAAW;EACX,aAAa;EACb,yBAAyB;AAC3B;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,8BAA8B;EAC9B,WAAW;EACX,aAAa;EACb,yBAAyB;AAC3B;AACA;IACI,qBAAqB;EACvB;;EAEA;IACE;EACF;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE;EACF;;;EAGA;IACE,qBAAqB;EACvB;;EAEA;IACE;EACF;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE;EACF;;;EAGA;IACE,WAAW;IACX,kBAAkB;EACpB","sourcesContent":[".card1{\r\n  margin-left:20px;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  width:250px;\r\n  height: 100px;\r\n  border: 2px solid skyblue;\r\n}\r\n.card2{\r\n  margin-left:20px;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  width:250px;\r\n  height: 100px;\r\n  border: 2px solid skyblue;\r\n}\r\n.card3{\r\n  margin-left:20px;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  width:250px;\r\n  height: 100px;\r\n  border: 2px solid skyblue;\r\n}\r\n.card4{\r\n  margin-left:20px;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  width:250px;\r\n  height: 100px;\r\n  border: 2px solid skyblue;\r\n}\r\n.card1:hover {\r\n    border:3px solid #222;\r\n  }\r\n\r\n  .card1:hover .list-group-item{\r\n    background:#00ffb6 !important\r\n  }\r\n\r\n  .card2:hover {\r\n    border:3px solid #222;\r\n  }\r\n\r\n  .card2:hover .list-group-item{\r\n    background:#00C9FF !important\r\n  }\r\n\r\n\r\n  .card3:hover {\r\n    border:3px solid #222;\r\n  }\r\n\r\n  .card3:hover .list-group-item{\r\n    background:#ff95e9 !important\r\n  }\r\n\r\n  .card4:hover {\r\n    border:3px solid #222;\r\n  }\r\n\r\n  .card4:hover .list-group-item{\r\n    background:#FF8133 !important\r\n  }\r\n\r\n\r\n  .card:hover .btn-outline-dark{\r\n    color:white;\r\n    background:#212529;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
